/**
 * This component will add a copy to clipboard functionality
 *
 * @module views/components/CopyToClipboard
 * @memberof -Common
 */
import './CopyToClipboard.scss';

import React, { useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Text from '@ulta/core/components/Text/Text';
import { CLIENT_ACTION_TYPES } from '@ulta/core/utils/constants/action';

import { copyTextToClipboard } from '@ulta/utils/Formatters/Formatters';

const DEFAULT_BUTTON_PROPS = {
  size: 'tiny',
  variant: 'tertiary'
};

/**
 * copyToClipAction onClick handler function for the copy button
 *
 * @function
 * @param {Object} data - object with copyText, setIsCopied, and copyToClipAction
 * @param {String} data.copyText - text to be copied
 * @param {Function} data.setIsCopied - function to set isCopied state
 * @param {Object} data.copyToClipAction - copyToClipAction object
 * @returns {Promise<void>} - resolves when the copy is completed
 */
export const copyText = async( data ) => {
  const { couponCode, setIsCopied, copyToClipAction } = data || {};

  if( !couponCode || !copyToClipAction || !setIsCopied ){
    return null;
  }

  if( copyToClipAction?.clientActionType !== CLIENT_ACTION_TYPES.COPYTEXT_CLICK ){
    await copyTextToClipboard( couponCode );
  }
  setIsCopied( true );
  setTimeout( () => {
    setIsCopied( false );
  }, 5000 );
};

/**
 * Represents a CopyToClipboard component
 *
 * @method
 * @param {CopyToClipboardProps} props - React properties passed from composition
 * @returns CopyToClipboard
 */
export const CopyToClipboard = function( props ){
  const {
    couponCode,
    couponCodeLabel,
    copyIcon,
    copyToClipAction,
    copyClipAccessibilityLabel,
    copiedClipAccessibilityLabel,
    copiedToClipLabel,
    withCodeLabel = true,
    withCopyIcon = true,
    buttonProps = DEFAULT_BUTTON_PROPS
  } = props;
  const [isCopied, setIsCopied] = useState( false );
  const navigatorObj = global.navigator?.clipboard;
  // TODO: need to refactor this once action.copyText is implemented from DXL for action model
  const buttonAction = useMemo(
    () => getButtonAction( { couponCode, copyToClipAction } ),
    [couponCode, copyToClipAction]
  );

  return (
    <div className='CopyToClipboard'>
      { withCodeLabel && couponCodeLabel && couponCode && (
        <span className='CopyToClipboard__codeLabel'>
          <Text htmlTag='span'
            textStyle='body-1'
          >
            { couponCodeLabel }
          </Text>
          <Text htmlTag='span'
            textStyle='body-1'
          >
            { couponCode }
          </Text>
        </span>
      ) }
      { copyToClipAction?.label && navigatorObj && (
        <span
          className='CopyToClipboard__copyToClipAction'
          title={
            isCopied ? copiedClipAccessibilityLabel : copyClipAccessibilityLabel
          }
          aria-live='polite'
          role='status'
        >
          { isCopied ? (
            <Button
              iconImage='Check'
              iconSize={ 's' }
              label={ copiedToClipLabel }
              size={ buttonProps.size }
              variant={ buttonProps.variant }
            />
          ) : (
            <Button
              action={ buttonAction }
              iconImage={ withCopyIcon ? 'copy' : undefined }
              iconSize={ 's' }
              label={ copyToClipAction.label }
              onClick={ () => {
                copyText( { couponCode, setIsCopied, copyToClipAction } );
              } }
              size={ buttonProps.size }
              variant={ buttonProps.variant }
            />
          ) }
        </span>
      ) }
    </div>
  );
};

/**
 * Get the button action object, if no copyText is available in the action object, then add it from the couponCode
 *
 * @param {Object} data - data object
 * @param {String} data.couponCode - coupon code
 * @param {Object} data.copyToClipAction - copyToClipAction object
 * @returns {Object} button action object
 */
export const getButtonAction = ( data ) => {
  const { couponCode, copyToClipAction } = data;
  let buttonAction = copyToClipAction;
  if( !copyToClipAction?.copyText && couponCode ){
    buttonAction = {
      ...buttonAction,
      copyText: couponCode
    };
  }
  return buttonAction;
};

/**
 * Property type definitions
 * @typedef CopyToClipboardProps
 * @type {object}
 * @property {string} buttonProps - Sets the button properties for the coupon code button, like size and style
 * @property {object} copyToClipAction - Sets the action for copy to clipboard
 * @property {string} copiedToClipLabel - Sets the label of the copied
 * @property {string} copyIcon - Sets the icon for copy
 * @property {string} copyClipAccessibilityLabel - Sets the label for copy clip
 * @property {string} copiedClipAccessibilityLabel - Sets the accessibility label for copied
 * @property {string} couponCodeLabel - Sets the label for coupon code
 * @property {string} couponCode - Sets the code value for coupon code
 * @property {string} withCodeLabel - True if the code label to be copied should be displayed; otherwise, false
 * @property {string} withCopyIcon - True if the copy icon label should be displayed; otherwise, false
 */
export const propTypes = {
  buttonProps: PropTypes.shape( {
    size: PropTypes.string,
    variant: PropTypes.string
  } ),
  copiedToClipLabel: PropTypes.string,
  copyToClipAction: PropTypes.object,
  copyClipAccessibilityLabel: PropTypes.string,
  copiedClipAccessibilityLabel: PropTypes.string,
  couponCodeLabel: PropTypes.string,
  couponCode: PropTypes.string,
  copyIcon: PropTypes.string,
  withCodeLabel: PropTypes.bool,
  withCopyIcon: PropTypes.bool
};

CopyToClipboard.propTypes = propTypes;

export default CopyToClipboard;
