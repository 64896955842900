/**
 * Create the Featured Promoted Compact Card component. This will replace the compact narrow component. You should be able to re-use the code from compact narrow with some updates.
 *
 * @module views/components/FeaturedPromotedCompactCard
 * @memberof -Common
 */
import './FeaturedPromotedCompactCard.scss';

import React, { useRef } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import GridContainer from '@ulta/core/components/GridContainer/GridContainer';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Spacer from '@ulta/core/components/Spacer/Spacer';
import Text from '@ulta/core/components/Text/Text';
import UltaAsset from '@ulta/core/components/UltaAsset/UltaAsset';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { handleIntersection } from '@ulta/core/utils/intersectionProcessor/intersectionProcessor';

import CopyToClipboard from '@ulta/components/CopyToClipboard/CopyToClipboard';

import { constants } from '@ulta/utils/constants/constants';

/**
  * Represents a FeaturedPromotedCompactCard component
  *
  * @method
  * @param {FeaturedPromotedCompactCardProps} props - React properties passed from composition
  * @returns FeaturedPromotedCompactCard
  */
export const FeaturedPromotedCompactCard = function( props ){
  const featuredPromotedCompactCardRef = useRef( null );
  const {
    backgroundColor,
    couponDetail,
    headline,
    subheadline,
    image,
    linkAction,
    buttonAction,
    rootMargin,
    root,
    threshold
  } = props;

  useIntersectionObserver( featuredPromotedCompactCardRef, {
    root: root,
    rootMargin: rootMargin,
    threshold: threshold
  }, handleIntersection( props ) );

  if( !headline || !subheadline ){
    return null;
  }

  const copyText = couponDetail?.copyText;
  const couponAppliedLabel = couponDetail?.couponAppliedLabel;
  const withCouponCode = copyText && couponAppliedLabel;

  return (
    <div className='FeaturedPromotedCompactCard'
      ref={ featuredPromotedCompactCardRef }
    >
      <GridContainer>
        <div className={ classNames( 'FeaturedPromotedCompactCard__content', {
          [`FeaturedPromotedCompactCard__content--${backgroundColor}`]: backgroundColor,
          'FeaturedPromotedCompactCard__content--noImageWithLinkOption': linkAction?.label && !buttonAction?.label && !image,
          'FeaturedPromotedCompactCard__content--noImageWithButtonOption': !linkAction?.label && buttonAction?.label && !image,
          'FeaturedPromotedCompactCard__content--imageWithLinkOption': linkAction?.label && !buttonAction?.label && image,
          'FeaturedPromotedCompactCard__content--noCallToActionWithImage': !linkAction?.label && !buttonAction?.label && image,
          'FeaturedPromotedCompactCard__content--noCallToActionNoImage': !linkAction?.label && !buttonAction?.label && !image
        } )
        }
        >
          { image &&
            <div className='FeaturedPromotedCompactCard__image'>
              <UltaAsset
                { ...( image ) }
              />
            </div>
          }
          { headline &&
            <div className='FeaturedPromotedCompactCard__titleContainter'>
              <div className={ classNames( 'FeaturedPromotedCompactCard__headline', {
                'FeaturedPromotedCompactCard__headline--position': image
              } )
              }
              >
                <div className='FeaturedPromotedCompactCard__headline--top'>
                  <Text
                    htmlTag={ headline.htmlTag }
                    textStyle={ headline.textStyle ? headline.textStyle : 'title-6' }
                    color={ headline.color }
                    textAlign={ headline.textAlign }
                  >
                    { headline.text }
                  </Text>
                  { headline.spacerValue &&
                    <Spacer value={ headline.spacerValue } />
                  }
                </div>
                { subheadline && (
                  <Text
                    htmlTag='span'
                    textStyle='body-2'
                    color='neutral-800'
                  >
                    { subheadline }
                  </Text>
                ) }
              </div>
            </div>
          }
          { linkAction && linkAction.label && (
            <div className={ classNames( 'FeaturedPromotedCompactCard__link', {
              'FeaturedPromotedCompactCard__link--position': image
            } )
            }
            >
              <Link_Huge
                action={ linkAction }
                withHover
                ariaLabel={ linkAction.label + ' ' + ( headline.text ) }
              >
                { linkAction.label }
              </Link_Huge>
            </div>
          )
          }
          { buttonAction && buttonAction.label &&
            <div className={ classNames( 'FeaturedPromotedCompactCard__button', {
              'FeaturedPromotedCompactCard__button--position': image
            } )
            }
            >
              { withCouponCode ? (
                <CopyToClipboard
                  withCodeLabel={ false }
                  withCopyIcon={ false }
                  buttonProps={ { variant: 'secondary', size: 'compact' } }
                  couponCode={ copyText }
                  couponCodeLabel={ buttonAction.label }
                  copyToClipAction={ buttonAction }
                  copyClipAccessibilityLabel={ `${buttonAction.label} ${copyText}` }
                  copiedClipAccessibilityLabel={ `${couponAppliedLabel} ${copyText}` }
                  copiedToClipLabel={ couponAppliedLabel }
                />
              ) : (
                <Button
                  compact
                  secondary
                  label={ buttonAction.label }
                  withHover
                  ariaLabel={ `${buttonAction.label} ${headline.text}` }
                  action={ buttonAction }
                />
              ) }
            </div>
          }
        </div>
      </GridContainer>
    </div>
  );
};

/**
  * Property type definitions
  * @typedef FeaturedPromotedCompactCardProps
  * @type {object}
  * @property {object} image - Image Asset Image
  * @property {object} headline - Sets the headline properties
  * @property {string} subheadline - Subheadline
  * @property {object} linkAction - Link Action
  * @property {object} buttonAction - Button Action
  * @property {string} backgroundColor - Background color
  * @property {object} couponDetail - Sets the coupon details for CopyToClipboard
  */

export const propTypes =  {
  image: PropTypes.object,
  headline:  PropTypes.object,
  subheadline:  PropTypes.string,
  linkAction: PropTypes.object,
  buttonAction: PropTypes.object,
  backgroundColor: PropTypes.string,
  couponDetail: PropTypes.object
};

FeaturedPromotedCompactCard.propTypes = propTypes;

export const defaultProps = {
  ...constants.INTERSECTION_OBSERVER_OPTIONS
};

FeaturedPromotedCompactCard.defaultProps = defaultProps;

export default FeaturedPromotedCompactCard;